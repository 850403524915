.datatable-top-brand {
  height: 277px;
  width: "100%";
}

.polar-chart-wrapper {
  width: 100%;
  height: 90%;
}
.polar-chart {
  width: 300px;
  height: 277px;
  margin: auto;
}
