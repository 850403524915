.menu-text {
  color: #fff;
  font-weight: 700 !important;
  font-size: 1.1rem !important;
}

.menu-text-active {
  color: #212121;
  font-weight: 700 !important;
  font-size: 1.1rem !important;
}

.sub-menu-text {
  color: #fff;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.sub-menu-text-active {
  color: #212121;
  font-weight: 400 !important;
  font-size: 1rem !important;
}
