.copy-info {
  width: 28px !important;
  height: 28px !important;
  margin-top: 2px !important;
}

.copy-info-help {
  font-size: 0.9rem;
  line-height: 1rem;
}

.copy-input {
  padding: 1px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  border-radius: 0.3rem;
  height: 42px;
}

.copy-input fieldset {
  border: hidden;
}

.copy-info-caution {
  color: #ec4129 !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
}
.copy-info-caution-icon {
  color: #ec4129 !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  margin-top: 2px;
}

.copy-info-caution-more {
  color: #999 !important;
  font-size: 0.85rem !important;
}

.type-notice {
  position: absolute;
  top: 0px;
  right: 30px;
  width: 104px;
  height: 28px;
  font-size: 0.9rem;
  line-height: 28px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  color: #ffffff !important;
}

.result-wrapper {
  width: 100%;
}

.result-title {
  font-size: 1.2rem !important;
  color: #1561C4 !important;
  font-family: inherit !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
}

.result-content {
  /* font-size: 1.2rem !important; */
  color: #444 !important;
  font-family: inherit !important;
  font-weight: 500 !important;
  line-height: 1.3 !important;
}

.btn-make-more {
  border: 1px solid #1561C4 !important;
  border-radius: 1rem !important;
  display: inline !important;
  font-style: normal !important;
  font-size: 0.85rem !important;
  padding: 0.2rem 0.5rem 0rem 0.5rem !important;
  font-weight: 500 !important;
}

.btn-meem-list {
  border: 1px solid #B22222 !important;
  border-radius: 1rem !important;
  display: inline !important;
  font-style: normal !important;
  font-size: 0.85rem !important;
  color: #B22222 !important;
  padding: 0.2rem 0.5rem 0rem 0.5rem !important;
  font-weight: 500 !important;
  position: relative;
  right: 10px;
}

.btn-meem-detail {
  border: 1px solid #1561C4 !important;
  border-radius: 1rem !important;
  display: inline !important;
  size: 0.5rem !important;
  font-style: normal !important;
  font-size: 0.5rem !important;
  padding: 0rem 0.3rem 0rem 0.3rem !important;
  font-weight: 700 !important;
}

.type-notice-history {
  position: absolute;
  top: 0px;
  right: 8rem;
  width: 104px;
  height: 28px;
  font-size: 0.9rem;
  line-height: 28px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  color: #ffffff !important;
}

.cate-tab {
  padding: 6px 16px !important;
  border: 1px solid#ddd !important;
  border-radius: 17px !important;
  margin-right: 4px !important;
  cursor: pointer !important;
  line-height: 1 !important;
  margin-top: 10px !important;
}

.cate-tab.active {
  border: 0 !important;
  background-color: #1561C4 !important;
  color: #fff !important;
}

.css-gtuokl-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  border: 0 !important;
  background-color: #1561C4 !important;
  color: #fff !important;
}

.category {
  padding: 0 !important;
  font-size: 0.875rem !important;
  color: #999;
}
.radio_color {
  font-size: 0.875rem !important;
  color: #000 !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 0.875rem !important;
}
